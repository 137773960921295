@media screen and (min-width: 576px) {
    app-profile {
        .profile-inner {
            width: 80%;
            margin: auto;
        }
    }

    app-create-edit-bulletinboard-entry {
        form {
            width: 80%;
            margin: var(--spacing-lg) auto 0;
        }

        ion-footer {
            ion-button {
                width: 80% !important;
                margin: auto;
            }
        }
    }

    app-login > ion-content > ion-row {
        width: 70%;
        margin: auto;
    }

    app-ticket-details {
        .ticket-details,
        ion-footer > ion-toolbar > ion-row {
            width: 70%;
            margin: auto;
        }
    }

    app-search {
        ion-slides {
            width: 70%;

            ion-searchbar,
            ion-button.searchbar-button {
                margin: 10px auto 0px;
            }
        }
    }

    app-create {
        ion-slides,
        ion-footer > ion-toolbar > ion-row {
            width: 70%;
            margin: auto;
        }
    }

    app-start {
        img {
            width: 50% !important;
        }
    }
}

@media screen and (min-width: 768px) {
    ion-tab-bar {
        ion-tab-button {
            padding: unset;
        }
    }
    app-ticket-view {
        height: 100%;

        .ticket-view-wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            app-ticket-card,
            app-skeleton-card {
                width: 50%;
            }
        }

        app-no-data {
            margin: auto;
        }
    }

    app-bulletinboard,
    app-bulletinboard-individual {
        .bulletin-board-cards {
            display: flex;
            flex-wrap: wrap;

            app-bulletin-board-card,
            app-skeleton-bulletin-entry {
                width: 50%;
            }
        }

        ion-searchbar {
            width: 70% !important;
            margin: 10px auto 0px;
        }
    }

    app-property,
    app-service-provider,
    app-flat,
    app-tenant {
        & > ion-content > .ion-margin-top,
        .ion-margin-vertical {
            width: 80%;
            margin: var(--margin-top) auto;
        }
    }
}

@media screen and (min-width: 1200px) {
    app-ticket-view {
        app-ticket-card,
        app-skeleton-card {
            width: 33.3% !important;
        }
    }

    app-profile {
        .profile-inner {
            width: 50%;
            margin: auto;
        }
    }

    app-ticket-details {
        .ticket-details,
        ion-footer > ion-toolbar > ion-row {
            width: 50%;
            margin: auto;
        }
    }

    app-create-edit-bulletinboard-entry {
        form,
        ion-segment {
            width: 50% !important;
            margin: var(--spacing-lg) auto 0px;
        }

        ion-footer {
            ion-button {
                width: 50% !important;
                margin: auto;
            }
        }
    }

    app-search {
        ion-slides {
            width: 50%;

            ion-searchbar {
                margin: 10px auto 0px;
            }
        }
    }

    app-create {
        ion-slides,
        ion-footer > ion-toolbar > ion-row {
            width: 50%;
            margin: auto;
        }
    }

    app-login > ion-content > ion-row {
        width: 50%;
        margin: auto;
    }

    app-start {
        img {
            width: 30% !important;
        }
    }

    app-bulletinboard,
    app-bulletinboard-individual {
        .bulletin-board-cards {
            display: flex;
            flex-wrap: wrap;

            app-bulletin-board-card,
            app-skeleton-bulletin-entry {
                width: 33.3%;
            }
        }

        ion-searchbar {
            width: 50% !important;
            margin: 10px auto 0px;
        }
    }

    app-property,
    app-service-provider,
    app-flat,
    app-tenant,
    app-group {
        & > ion-content > .ion-margin-top,
        .ion-margin-vertical {
            width: 50%;
            margin: var(--margin-top) auto;
        }
    }
}

app-bulletinboard,
app-profile,
app-news {
    ion-header {
        ion-title {
            text-align: center;
        }
    }
}
