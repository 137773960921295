ion-fab-button,
ion-icon {
    outline: none !important;
}

ion-chip > ion-label {
    line-height: 15px;
}

ion-avatar > img {
    transform: none !important;
}

form {
    ion-item.item-interactive.item-has-focus {
        --highlight-background: var(--ion-color-primary);
    }
}

ion-refresher {
    z-index: 0 !important;
}

ion-modal {
    app-topbar {
        ion-title.md {
            padding: 0 !important;
        }
        ion-icon {
            font-size: 40px !important;
        }
    }

    ion-header > ion-toolbar > ion-buttons.ios {
        ion-icon[ng-reflect-name='close'],
        ion-icon[ng-reflect-name='add'] {
            font-size: 40px !important;
        }
    }
}

ion-toolbar > ion-buttons {
    ion-button {
        margin: auto !important;
    }
    ion-icon[ng-reflect-name='close'],
    ion-icon[ng-reflect-name='add'] {
        font-size: 30px;
    }
}
