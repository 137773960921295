.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.h-50 {
    height: 50%;
}

.h-25 {
    height: 25%;
}

.w-33 {
    width: 33.3%;
}

.w-50 {
    width: 50%;
}

.w-80 {
    width: 80%;
}

.w-60 {
    width: 60%;
}

.w-40 {
    width: 40%;
}

.w-30 {
    width: 30%;
}

.w-25 {
    width: 25%;
}

.w-20 {
    width: 20%;
}

.w-15 {
    width: 15%;
}

.w-10 {
    width: 10%;
}

.text-bold {
    font-weight: bold !important;
}

.item-button-style {
    border-radius: 6px;

    button {
        padding: 0;
    }
}

.text-light {
    color: #999;
}

.separator-top {
    border-top: var(--gray-border);
}

.separator {
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
}

.no-padding-top {
    padding-top: 0px !important;
}

.display-flex {
    display: flex;
}

.font-sm {
    font-size: var(--font-sm);
}

ion-card-header {
    .button-right {
        float: right;
        margin-top: -6px;
    }
}

.hidden {
    display: none;
}

ion-toolbar[color='primary'] {
    ion-title,
    ion-icon:not(.searchbar-search-icon.md) {
        color: white !important;
    }
}

ion-fab-button.tiny {
    height: 20px;
    width: 20px;
    ion-icon {
        font-size: 12px;
    }
}

ion-card {
    border-radius: var(--card-border-radius);

    .card-section {
        padding: var(--spacing-md) 0;

        .section-title {
            font-size: 16px;
            text-transform: uppercase;
            font-weight: bold;
            margin: 0 var(--spacing-sm) var(--spacing-md);
        }
    }
}

.accent-border {
    border-top: 2px solid var(--ion-color-primary);
}

.color-primary {
    color: var(--ion-color-primary);
}

ion-searchbar {
    text-align: left;
}

.total-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

ion-icon.rotated {
    transform: rotate(180deg);
    transition: rotate 0.3s;
}

.topbar-spacer {
    height: var(--header-height);
}

app-topbar.fixed {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
}

ion-item.no-inner-padding-end {
    --inner-padding-end: 0px;
}

.floating-button-spacing-bottom {
    height: 70px;
}

ion-content.height-minus-toolbar {
    &.md {
        height: calc(100% - 68px);
    }
    &.ios {
        height: calc(100% - 68px);
    }
}

ion-modal.fullscreen {
    --width: 100%;
    --height: 100%;
}

.slide-title {
    margin: var(--spacing-lg) var(--spacing-md);
    color: black;
    text-align: left;

    .ios & {
        text-align: center;
    }
}

.scroll {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 600px) {
    .small-modal .modal-wrapper {
        height: 90%;
        width: 90%;
    }
}
