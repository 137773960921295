// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #c8af88;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #c8af88;
    --ion-color-primary-tint: #c8af88;

    /** secondary **/
    --ion-color-secondary: #223342;
    --ion-color-secondary-rgb: 12, 209, 232;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #223342;
    --ion-color-secondary-tint: #223342;

    /** tertiary **/
    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;

    /** success **/
    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;

    /** warning **/
    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;

    /** danger **/
    --ion-color-danger: #ff7f7f;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #ff7f7f;
    --ion-color-danger-tint: #ff7f7f;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-color-darkgray: #888888;

    --gray-border: 0.0625rem solid #eee; // 1px

    /** default font-size **/
    --default-font-size: 16sp;
    --default-font-size-ios: 13pt;

    --segment-slider-header-background: white;
    --segment-slider-header-height: 3.125rem; // 50px
    --header-height: 3.25rem;
    --header-height-ios: 2.25rem; // 44px
    --header-button-padding: 0.25rem; // 4px
    --header-box-shadow-android: 0px 0.125rem 0.3125rem 0px rgba(0, 0, 0, 0.35); // 0px, 2px, 5px, 0px
    --header-border-ios: 0.034375rem solid rgba(0, 0, 0, 0.2); // 0.55px
    --segment-slider-badge-width: 1.5625rem; // 25px if you change this, also change the value in segment-slider.component
    --tab-height: 4rem;

    --spacing-xs: 0.25rem; /* 4px */
    --spacing-sm: 0.5rem; /* 8px */
    --spacing-md: 1rem; /* 16px */
    --spacing-lg: 1.5rem; /* 24px */
    --card-dropshadow-width: 0.625rem; /* 10px */
    --card-margin: 0.625rem; /* 10px */
    --card-border-radius: 0.625rem; /* 10px */
    --textarea-border-radius: 0.625rem; /* 10px */
    --ion-padding: 1rem; /* 16px */
    --chat-input-footer-height: 4.625rem; /* 74px */
    --avatar-in-item-size: 3.125rem; /* 50px */
    --chat-avatar-size: 3.75rem; /* 60px */
    --chat-button-height: 3.125rem; /* 50px */
    --col-padding: 0.3125rem; /* 5px */
    --icon-size: 1.8rem;
    --icon-size-lg: 2.875rem; /* 46px */
    --font-sm: 0.875rem; /* 14px */
    --font-md: 1rem; /* 16px */

    --font-size-base: 1rem; /* 11pt / 11pt = 1rem */
    --font-size-small: 0.9091rem; /* 10pt */
    --font-size-caption: 0.8182rem; /* 9pt */
    --font-size-h3: 1.6364rem; /* 18pt */
    --font-size-h2: 1.8182rem; /* 20pt */
    --font-size-h1: 2.1818rem; /* 24pt */
    --font-size-large: 2.5455rem; /* 28pt */
    --font-size-xlarge: 2.9091rem; /* 32pt */

    .ios {
        --ion-safe-area-top: 0 !important;
    }

    --woonig-border-radius: 6px;
}
